<!--
 * @Date: 2023-11-28 16:39:59
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-08-23 17:49:26
 * @FilePath: \zyt-mobile-frontend\src\views\cinemaAssistant\competitorCinema\attendance.vue
-->
<template>
  <div>
    <div>
      <ve-table
        :scroll-width="0"
        :table-data="tableData"
        :max-height="maxHeight"
        :fixed-header="true"
        :show-header="true"
        :border-y="true"
        :highlight-hover-row="true"
        :highlight-current-row="true"
        :columns="columns"
        v-if="tableData.length > 0"
      ></ve-table>
      <div v-else>
        <van-empty description="暂无数据"></van-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { zjApi } from '@/api/index'

export default {
  data() {
    return {
      // 表格最大高度
      maxHeight: 900,
      ourCinemaId: null,
      tableData: [],
      columns: [
        // { field: "name", key: "a", title: "Name", align: "center" },
        {
          title: '竞对影院名称',
          field: 'cinemaName',
          key: 'cinemaName',
          width: 130,
          fixed: 'left',
        },
        {
          title: '影院位置',
          field: 'cinemaLocation',
          key: 'cinemaLocation',
          width: 150,
        },
        {
          title: '距离',
          field: 'distance',
          key: 'distance',
          width: 80,
          renderBodyCell: ({ row }) => {
            return row.distance + 'km'
          },
        },
        {
          title: '昨天',
          field: 'yesterday',
          key: 'yesterday',
          width: 80,
          renderBodyCell: ({ row }) => {
            return row.distance  + '%'
          },
        },
        {
          title: '最近7天',
          field: 'lastSevenDays',
          key: 'lastSevenDays',
          width: 80,
          renderBodyCell: ({ row }) => {
            return row.distance  + '%'
          },
        },
        {
          title: '最近30天',
          field: 'lastThirtyDays',
          key: 'lastThirtyDays',
          width: '25',
          renderBodyCell: ({ row }) => {
            return row.distance  + '%'
          },
        },
        //操作
        {
          title: '详情',
          field: 'detail',
          key: 'detail',
          width: 60,
          fixed: 'right',
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field]
            // console.log({ row, column, rowIndex });
            if (rowIndex === 0) {
              return <span>-</span>
            } else {
              return (
                <span
                  style="color: #1989fa;cursor: pointer;"
                  onClick={() => {
                    this.$router.push({
                      path: '/cinemaAssistant/competitorCinema/attendance/detail',
                      query: {
                        id: row.id,
                        ourCinema: JSON.stringify(this.ourCinema),
                      },
                    })
                  }}
                >
                  详情
                </span>
              )
            }
          },
        },
      ],
    }
  },
  mounted() {
    // 动态计算表格最大高度
    this.maxHeight = window.innerHeight - 10
    this.ourCinemaId = this.$route.query.cinemaId
    this.getData()
  },
  methods: {
    // 获取数据
    getData() {
      const query = {
        UserCode: this.$store.state.userInfo.mobile,
        ourCinemaId: this.ourCinemaId,
      }
      // 获取数据
      zjApi.getAnalysisSeatRate(query).then((res) => {
        console.log(res)
        const { code, result } = res
        this.tableData = result 
        if (result.length > 0) {
          this.ourCinema = result[0]
        }
      })
    },
  },
}
</script>
